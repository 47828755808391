<template>
	<div class="customer-template-setting">
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Contract</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="border pb-3 mb-5">
			<v-layout class="py-2 px-4 blue lighten-5">
				<v-flex class="">
					<div class="fw-600 sub-heading primary--text text-capitalize">Contract #</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="d-flex justify-content-end">
					<v-btn
						class="white--text"
						dense
						tile
						color="blue darken-4"
						v-on:click="updateContractSetting()"
					>
						Update
					</v-btn>
				</v-flex>
			</v-layout>
			<v-layout class="px-4 py-2">
				<v-row>
					<v-col cols="12" lg="4" md="4">
						<label for="prefix1">Prefix</label>
						<v-text-field outlined id="prefix1" hide-details v-model="setting.prefix"></v-text-field>
					</v-col>
					<v-col cols="12" lg="4" md="4">
						<label for="next-number">Next Number</label>
						<v-text-field
							outlined
							id="next-number"
							type="number"
							hide-details
							v-model="setting.next_number"
						></v-text-field>
					</v-col>
					<v-col cols="12" lg="4" md="4">
						<label for="number-length">Number Length</label>
						<v-text-field
							outlined
							id="number-length"
							type="number"
							hide-details
							v-model="setting.number_length"
						></v-text-field>
					</v-col>
				</v-row>
			</v-layout>
		</div>
		<div class="border pb-3 mb-5">
			<v-layout class="py-2 px-4 blue lighten-5">
				<v-flex class="">
					<div class="fw-600 sub-heading primary--text text-capitalize">Contract Remarks</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="d-flex justify-content-end">
					<v-btn
						class="white--text"
						dense
						tile
						color="blue darken-4"
						v-on:click="updateContractSetting()"
					>
						Update
					</v-btn>
				</v-flex>
			</v-layout>
			<v-layout class="px-4 py-2">
				<v-row>
					<v-col cols="12" lg="12" md="12">
						<v-textarea
							hide-details
							outlined
							placeholder="Remarks"
							v-model="setting.remarks"
						></v-textarea>
					</v-col>
				</v-row>
			</v-layout>
		</div>
	</div>
</template>
<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "ContractSetting",
	data() {
		return {
			setting: {
				prefix: null,
				next_number: null,
				number_length: null,
				remarks: null,
			},
		};
	},
	methods: {
		updateContractSetting() {
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `setting/contract`,
					data: this.setting,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contract setting updated successfully." },
					]);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getContractSetting() {
			this.$store
				.dispatch(GET, { url: `setting/contract` })
				.then((data) => {
					this.setting = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		goBack() {
			this.$router.go(-1);
		},
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(query) {
				if (query && query.tab == "contract") {
					this.getContractSetting();
				}
			},
		},
	},
	mounted() {
		this.getContractSetting();
	},
};
</script>
